<template>
  <div class="home">
    <div class="iframeStyle">
      <!-- <h1 class="hStyle">社区功能待完善，敬请期待！</h1> -->
      <iframe
        src="http://flarum.yangwudongxi.com/"
        style="width: 100%; height: 100%"
        frameborder="0"
        scrolling="yes"
        id="bdIframe"
      ></iframe>
    </div>
    <div class="footer">
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import Footer from './Footer.vue'
export default {
  name: '',
  data() {
    return {}
  },
  components: {
    'footer-component': Footer
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.home {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1100px;
  background-color: rgba(0, 0, 0);
}
.iframeStyle {
  position: absolute;
  top: 100px;
  left: 0;
  width: 1920px;
  height: 900px;
}
.hStyle {
  line-height: 600px;
  color: rgba(255, 255, 255, 0.5);
}
.footer {
  position: absolute;
  top: 900px;
  left: 0;
  width: 1920px;
  height: 200px;
}
</style>
